export const environment = {
  production: true,
  gestaoEnergia: 'https://gestao.safiraon.com.br/',
  inteligenciaMercado: 'https://inteligencia.safiraenergia.com.br/',
  gestaoBackendUrl: 'https://safira-gestao-producao.uc.r.appspot.com/',
  authConfig: {
    scope: 'openid profile email',
    responseType: 'code',
    oidc: true,
    clientId: '169995013227348225@safira-on',
    issuer: 'https://auth.safiraon.com.br',
    redirectUri: 'https://on.safiraenergia.com.br/home',
    postLogoutRedirectUri: 'https://on.safiraenergia.com.br',
    requireHttps: false,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAtJe2CQI_QXnmtJU15dC0Vy15g6nkO0Sw',
    authDomain: 'safira-on-prod.firebaseapp.com',
    projectId: 'safira-on-prod',
    storageBucket: 'safira-on-prod.appspot.com',
    messagingSenderId: '1083828345518',
    appId: '1:1083828345518:web:794650f0c9699b7f11ab3f',
    measurementId: 'G-GJSCD3KHWJ',
  },
  analytics: {
    gtmId: 'GTM-NC8H8TT',
  },
  terms: {
    tos: 'https://firebasestorage.googleapis.com/v0/b/onboardingacl.appspot.com/o/v2%2Fassets%2FTermos%20de%20Uso%20-%20Safira%20On.pdf?alt=media&token=d3e9dc4b-abfe-4912-8143-9c7bfe984a40',
    privacy:
      'https://firebasestorage.googleapis.com/v0/b/onboardingacl.appspot.com/o/v2%2Fassets%2FPolitica%20de%20Privacidade%20-%20Safira%20On.pdf?alt=media&token=c6b6c2eb-dabc-4595-8bd3-9879ca872da4',
  },
  cms: {
    repo: 'safiraprisma',
    docType: 'gim_post',
  },
  cypress: {
    desktopResolution: {
      width: 1920,
      height: 1080,
    },
    mobileResolution: {
      width: 390,
      height: 844,
    },
  },
  profiles: {
    representative: 'cmVwcmVzZW50YW50ZQ==',
  },
  zitadelToken: 'zitadel:access_token',
}
