import { Component, OnInit } from '@angular/core'
import { GoogleTagManagerService } from 'angular-google-tag-manager'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private gtmService: GoogleTagManagerService) {}

  ngOnInit(): void {
    this.gtmService.addGtmToDom()

    setTimeout(() => {
      const cachedPage = localStorage.getItem('cached_page')
      if (cachedPage) {
        window.location.href = cachedPage
        localStorage.removeItem('cached_page')
      }
    }, 0)
  }
}
