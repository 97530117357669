/* eslint-disable arrow-body-style */
import { BrowserModule } from '@angular/platform-browser'
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx'
import { IonicModule } from '@ionic/angular'
import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule } from '@angular/common/http'
import {
  StatehandlerService,
  StatehandlerServiceImpl,
} from 'src/shared/auth/statehandler.service'
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core'
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'
import {
  StatehandlerProcessorService,
  StatehandlerProcessorServiceImpl,
} from 'src/shared/auth/statehandler-processor.service'
import { StorageService } from 'src/shared/auth/storage.service'
import { initializeApp, analytics, functions } from 'firebase'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import * as moment from 'moment'
import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'

const authConfig: AuthConfig = environment.authConfig

const stateHandlerFn = (stateHandler: StatehandlerService) => {
  return () => {
    return stateHandler.initStateHandler()
  }
}

const app = initializeApp(environment.firebaseConfig)

// Descomentar para rodar functions local
// functions().useFunctionsEmulator('http://localhost:5001')

if (environment.production) {
  analytics(app)
}

registerLocaleData(localePt, 'pt')
moment.locale('pt-br')

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: environment.analytics.gtmId,
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          '<YOUR DOMAIN>/caos.zitadel.auth.api.v1.AuthService',
          '<YOUR DOMAIN>/oauth/v2/userinfo',
          '<YOUR DOMAIN>/caos.zitadel.management.api.v1.ManagementService/',
          'https://preview.api.zitadel.caos.ch',
        ],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: stateHandlerFn,
      multi: true,
      deps: [StatehandlerService],
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StatehandlerProcessorService,
      useClass: StatehandlerProcessorServiceImpl,
    },
    {
      provide: StatehandlerService,
      useClass: StatehandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    FCM,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
